<template>


  <KunstwerkenThumbnails />

  <VersieControle />

</template>

<script>



  
// @ is an alias to /src

import KunstwerkenThumbnails from '@/components/KunstwerkenThumbnails.vue'
import VersieControle from '@/components/VersieControle.vue'


export default {
  name: 'Home',
  components: {
        KunstwerkenThumbnails,
        VersieControle
  }
}





</script>
