
<template>




         <div 
                class="formulier_blok"       
                >


                 <h1 
                        v-if="event.boodschap != '' "
                        >
                        {{vertaal ( event.boodschap )}}
                </h1>

                <form
                        v-if="event.status != 'ok' "

                        @submit.prevent="verwerkInput"
                        >

                        <h1>{{vertaal ( 'contactformulier' )}}</h1>

                        <div
                             class="input_veld"
                             >

                             <label>{{vertaal ( 'naam' )}}</label>

                             <input
                                        type="text"
                                        class=""
                                        placeholder=""
                                        v-model="event.naam"
                                        required
                             />
                        </div>

                        <div
                             class="input_veld"
                             >

                             <label>{{vertaal ( 'email' )}}</label>

                             <input
                                  type="email" 
                                  id="email"
                                  class=""
                                  placeholder=""
                                  v-model="event.email"
                                  required
                             />

                        </div>




                        <div
                                class="input_veld"
                                >
                                <label>Uw bericht</label>

                                <textarea 
                                        v-model="event.tekst"
                                        cols="20"
                                        rows="8"
                                        class="form_textarea"

                                ></textarea>

                        </div>



                        <div
                             class="input_veld tekst_center"
                             >

                            <label> <br></label>

                             <button
                                type="submit"
                                class="button submit"
                                >

                                <span
                                        v-if="event.formtype == 0"
                                        >
                                            Verstuur
                                </span>

                                </button>

                        </div>

    </form>




        </div>




</template>


<script>

/*

                             v-if="event.formtype == 0"



            <label>{{this.vertaal ( 'wachtwoord' )}}</label>



            <p>{{getTypeNaam ( value2.adres_type_id  )}}</p>

*/



import { 
        reactive, 
       // computed 
    } from 'vue';

import store from '@/store'


export default {

    name: 'ContactLayout',

    // props: {

    //      //   selectie: String,
    // },

    components: {

    },





    setup ( ) {

        const event = reactive ({

                status: '',
                boodschap: '',

                naam: '',
                email: '',
                tekst: '',

                formtype: 0,


                // activeClass: 'formSelect',
                // standClass: '',

        });



        function vertaal ( code ) {

                //return code ;
                return store.getters.getVertaling ( code );
        }


        // function kiesAanmelden ( ) {

        //         return event.formtype = 1;
        // }

        // function kiesLogin ( ) {

        //         return event.formtype = 0;
        // }




        function initPaginaData () {

             //   store.dispatch ( 'laadVertaling' );   
                document.title = 'Contact | '  + this.vertaal ( 'naam_bedrijf' );


        }





        async function verwerkInput () {

                let message = JSON.stringify (
                        {
                                naam: event.naam,
                                email: event.email,
                                tekst: event.tekst,
                     //          t: 'contactformulier',
                        }
                );


               // alert ( 'registreer: ' + message ) ;

                let url = store.state.appDataUrl + '?t=contactformulier&message=' + encodeURI ( message ) ;

               //alert ( 'url: ' + url ) ;


                let res = await fetch ( 
                        url, 
                        {
                                method: "POST",
                              "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"                                    
                    //            headers: { "Content-Type": "application/json" },
                            //   message: message
                        }
                );

                //alert ( 'res: ' + res.toString() ) ;

                let data = await res.json();




                event.status               = data.data.status;
                event.boodschap      = data.data.boodschap;

               // alert ( 'boodschap: ' + data.toString() + event.boodschap ) ;
        }



       


                return { 
                        event, 
                        vertaal,
                        verwerkInput,
                        initPaginaData

                        // kiesAanmelden,
                        // kiesLogin,

              //          initPaginaData

                };
        },



          mounted () {

                 this.initPaginaData();
          },


              updated() {

                    this.initPaginaData();
              },


   //    updated() {

   // //        this.initPaginaData();
   //    },

}



</script>



<style scoped>




.formulier_blok 
{
        margin:   0;
        padding:  2rem 0;

        border-radius: 0;
}

.formulier_blok h1
{
        margin: 0 1rem 2rem 1rem;
        font-size: 1.2rem;
}


.formulier_blok form
{
        max-width: 40rem;

        margin:  1rem auto 3rem auto;
        padding: 1rem 0 0 0;

        background-color: rgb( 240, 240, 240 );

        text-align: center;

}


@media (min-width: 60rem) 
{
        .formulier_blok form
        {
                max-width: 40rem;

                margin:  1rem auto 3rem auto;
                padding: 1rem 0 0 0;

                border:  1px solid rgb( 240, 240, 240 );

              border-radius: .5rem;
        }

        .formulier_blok h1
        {
                font-size: 1.5rem;
        }

}





.input_veld 
{
        width:  24rem;
        max-width: calc( 100vw - 2rem );

        margin:  0 auto 1rem auto;

        text-align: left;

}


.input_veld:first-child 
{
        margin:  2rem 0 1rem 0;
}



.formulier_blok label 
{
        display: block;
        font-size: .9em;
}



input,
textarea 
{   
        width:  100%;

        border:  1px solid rgb( 200, 200, 200 );

        padding:  .25rem;

        border-radius: 4px;
}


textarea 
{
        height:  10rem;               
}


.tekst_center 
{
        text-align: center;
}

.button_blok 
{
            font-size: 1.1rem;
            line-height: 1em;
}



.button 
{
        display: inline-block;

        margin:  0 1rem 1rem 0;
        padding:  .5rem 1rem;

        font-weight: 400;

        border:  1px solid rgb( 220, 220, 220 );

        border-radius: .5rem;

        color:  rgb( 200, 200, 200 );
}


.button.submit
{
        color:  #fff;
        background-color: rgb(61, 202, 143);
              background-color: rgb(158, 213, 121);
              background-color: rgb( 63, 193, 255 );

        border:  none;

        font-size: 1.1rem;
        font-weight: 600;
}





.button.formSelect 
{
        font-weight: 600;
        color:  rgb( 255, 255, 255 );

        background-color: rgb( 200, 200, 200 );
}






.button:hover
{
        animation: kaart_button_hover linear .5s;
        animation-timing-function: ease;
        animation-iteration-count: 1;
        transform-origin: 50% 50%;
        animation-fill-mode: forwards; 

        color: #fff;
        background-color: rgb(0, 168, 249);



}




</style>
