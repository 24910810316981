import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Contact from '../views/Contact.vue'
import Favorieten from '../views/Favorieten.vue'



const routes = [
  {
        path: '/',
        name: 'Home',
        component: Home
  },
   {
        path: '/c',
        name: 'Contact',
        component: Contact
  },    
  {
        path: '/fav',
        name: 'Favorieten',
        component: Favorieten
  }, 
  {
        path: '/r',
        name: 'Rubriek',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/Rubriek.vue')
  }
  


  //,

  // {
  //   path: '/exposities',
  //   name: 'Exposities',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/Exposities.vue')
  // },

  // {
  //   path: '/teksten',
  //   name: 'Teksten',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/Teksten.vue')
  // },

  // {
  //   path: '/contact',
  //   name: 'Contact',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/Contact.vue')
  // },




]





const router = createRouter({
          history: createWebHashHistory(),
          routes,

           scrollBehavior() {

                  //document.getElementById('app').scrollIntoView();

                  // window.scrollTo(0,0);
            }
})


// scrollBehavior (to, from, savedPosition) {
//   if (savedPosition) {
//     return savedPosition
//   } else {
//     return { x: 0, y: 0 }
//   }
// }






export default router
