<template>
          <HoofdMenu />
          <router-view/>

            <VersieControle />
</template>


<script>


import { 
      reactive, 
//      computed
} from 'vue'

import store from '@/store'


import HoofdMenu from '@/components/HoofdMenu.vue'
import VersieControle from '@/components/VersieControle.vue'


export default {
  name: 'Walter Simon',

   components: {
         HoofdMenu,
         VersieControle


    // TcpIntro,
    // TcpBezoekers,
    // TcpDeelnemers,
 //   TaalKeuze
  },

   
    setup () {

        const event = reactive ({

                // data: computed (() => { 
                //        return a;
                // }),

                    //bg_foto: computed (() => { return "@/assets/introfoto/DJI_0454A.jpeg"  }),


                // taalkeuze: computed (() => { 

                //       return  store.getters.getTaalKeuze();
                // }),


            //     loading: computed (() => { 



            //     if ( store.state.vertaalLijst.length > 0 )
            //     {
            //             return false; 
            //     }

            //     return true;
            // }),


                  //  test: computed (() => { 

                  //     if ( store.state.vertaalLijst.length > 0 )
                  //     {
                  //             return store.state.vertaalLijst[0].code; 
                  //     }

                  //     return '-';
                  // }),
        });



            function setPaginaTitel () {

                    // if ( store.state.projectKeuzeData ) { 

                    //       document.title = store.state.projectKeuzeData.naam [ event.taalkeuze ] ; //+ ' | ' + event.subrubrieken [ event.subrubriek ];
                    // }  
            }

            
       const laadVertaling = async () => {
    
                   store.dispatch ( 'laadVertaling' );   
        }


        return { 
              event, 
              laadVertaling,
              setPaginaTitel 

            };
    },


      mounted() {

                this.laadVertaling();

                this.setPaginaTitel ();


              //  store.dispatch ( 'laadVertaling' );   
      },

      updated() {

                //this.laadVertaling();

            //    store.dispatch ( 'laadVertaling' );   
      },

}





</script>



<style>


@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@300;400;500&display=swap');


/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
  margin: 0;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role='list'],
ol[role='list'] {
  list-style: none;
}

/* Set core root defaults */
html:focus-within {
  scroll-behavior: smooth;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1.5;

/*                  background-color: rgb( 250, 250, 250 );*/
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}


a {
    text-decoration: none;
            color: rgb( 100, 100, 100 );
}


/* Make images easier to work with */
img,
picture {
  max-width: 100%;
  display: block;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
   scroll-behavior: auto;
  }
  
  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}


/*============================================================*/


#app {
    font-family: "Work Sans",  Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: rgb( 125, 125, 125 );

    
      margin:  0;
      padding:  0;
}


body
{
      margin:  0;
      padding:  0;

        color: rgb( 100, 100, 100 );

        font-size: 1.1rem;
}


/*
#nav {
  padding: 0;
}

#nav a {
  font-weight: bold;
  color: rgb( 100, 100, 100 );
}

#nav a.router-link-exact-active {
  color: rgb( 100, 100, 100 );
}*/


.about 
{
      margin:  4rem 0 0 0;
}
</style>
