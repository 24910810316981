
<template>


       <div 
                 v-if=" event.data != null "

                 class="pagina_blok"
                 >

                <div 
                        v-if=" event.data.length > 0 "
                        class="thumbnail_blok"
                        >
                 

                      <div 
                                class="thumbnail"

                                v-for="(value_beeld, key_beeld) in event.data" 
                                 :key="key_beeld" 

                                 v-bind:class="[event.bekekenbeeld == 'beeld' + key_beeld  ? event.activeClass : event.standClass, '' ]"  

                                v-bind:id= "'beeld' + key_beeld"
                                 >


                            <img 
                                        v-bind:src="value_beeld.img"
                                        class="beeld"
                                        loading='lazy' 
                                      />

                              <p><span v-html="value_beeld.tekst [ 1 ]"></span></p>


                              <div 
                                          v-on:click="unsetFotoKeuze ( value_beeld.id )" 
                                          class="hartje_stop"
                              ></div>


                          </div>


                </div>
  

      </div>



</template>


<script>

/*



<template>


       <div 
                 v-if=" event.data !== null "

                 class="pagina_blok"
                 >

                <div 
                        v-if=" event.data.length > 0 "
                        class="thumbnail_blok"
                        >
                 

                      <router-link 
                                v-for="(value_beeld, key_beeld) in event.data" 
                                 :key="key_beeld" 

                                 v-bind:class="[event.bekekenbeeld == 'beeld' + key_beeld  ? event.activeClass : event.standClass, '' ]"  

                                v-bind:id= "'beeld' + key_beeld"

                                 @click="bewaarLocatie ( 'beeld' + key_beeld )"

                                 :to="{path:'/r',  query:{i: value_beeld.rubriek_id, p: value_beeld.tekst_id, info: value_beeld.tekst_url }}"
                                 >



                        <img 
                                    v-bind:src="value_beeld.img"
                                    class="beeld"
                                   loading='lazy' 
                                  />

                          <p><span v-html="value_beeld.tekst [ 1 ]"></span></p>



                              <div 
                                          v-on:click="unsetFotoKeuze ( value_beeld.id )" 
                                          class="hartje_stop"
                              ></div>


                          </router-link>


                </div>
  

      </div>



</template>





                      <p>{{event.bekekenbeeld}}</p>

                                     class="thumbnail"

                                            <p><span v-html="value_beeld.tekst[ 1 ]"></span></p>

                <ul  
                        v-if="event.data.length > 1 "
                        class="links_blok"
                        >

                             <li 
                                    v-for="(value_tekst, key_tekst) in event.data" 
                                    :key="key_tekst" 
                                    v-bind:class="[event.tekstkeuze == value_tekst.tekst_id ? event.activeClass : event.standClass, '' ]"  
                                    >

                                        <router-link     
                                                 :to="{path:'/r',  query:{i: value_tekst.rubriek_id, p: value_tekst.tekst_id, info: value_tekst.url }}"
                                                >
                                            
                                                {{value_tekst.naam}}

                                        </router-link>
                             </li>
                  </ul>


{{event.tekstkeuze}}


                                            :class="event.imgIsLoaded ? 'show' : ''"
                                            loading="lazy"
                                            @load="imgLoadedMethod"



                        <div 
                              v-if=" event.navigatie.vorige > 0 "

                                v-on:click="setTekstKeuze ( event.navigatie.vorige )" 

                                class="button button_min"
                          >
                        </div>


                        <div 
                              v-if=" event.navigatie.volgende > 0 "

                                v-on:click="setTekstKeuze ( event.navigatie.volgende )" 

                                class="button button_plus"                        
                          >
                        </div>





                  <div class="links_blok">

                    <div 
                              v-for="(tekst_value, tekst_key) in event.data" 
                               :key="tekst_key" 

                               v-on:click="setTekstKeuze ( tekst_value.tekst_id )" 
                              >



                              <p
                                     v-bind:class="[event.tekstkeuze == tekst_value.tekst_id  ? event.activeClass : event.standClass, '' ]"  

                              >{{tekst_value.naam}}</p>

                    </div>
            </div>



vorige {{event.navigatie.vorige}}

                          volgende {{event.navigatie.volgende}}


                <div 
                      v-if=" event.tekstdata.beelden.length > 0 "
                      class="thumbnail_blok">

                          <div 
                                    v-for="(value_beeld, key_beeld) in event.tekstdata.beelden" 
                                     :key="key_beeld" 
                                    >

                                  <img 
                                             v-bind:src="value_beeld.img"
                                            >

                                    <p>{{value_beeld.tekst[ 1 ] }}</p>

                          </div>
                </div>



                  <div class="thumbnail_blok">

                    <div 
                              v-for="(tekst_value, tekst_key) in event.data" 
                               :key="tekst_key" 

                               v-on:click="setTekstKeuze ( tekst_value.tekst_id )" 
                              >



                              <p
                                     v-bind:class="[event.tekstkeuze == tekst_value.tekst_id  ? event.activeClass : event.standClass, '' ]"  

                              >{{tekst_value.naam}}</p>

                    </div>
            </div>






        <h1>{{event.tekstdata.kop}} {{event.tekstkeuze}}</h1>



        <h1>Kunstwerken Layout {{event.rubriekkeuze}}</h1>
    <div 
        v-if="event.data != null "

              class="adrespagina slowShow"
        >


          <!-- fotoblok ----------------------------------->




          </div>

                            <img 
                                       v-bind:src="value.img"
                                      >

          <div class="thumbnail_blok">

                    <div 
                              v-for="(value, key) in event.data.beelden" 
                               :key="key" 
                              >

                            <img 
                                       v-bind:src="value.img"
                                      >

                              <p>{{value.tekst}}</p>

                    </div>
            </div>



                  <div class="tags_blok">
                             {{event.data.tags [ event.taalkeuze ]}}
                  </div>


                                      v-bind:class="[event.regiokeuze == value.regio_id  ? event.activeClass : event.standClass, '' ]"  



                  <div  v-if="event.loading" >
                      <p>data laden</p>
                  </div>

                  <div
                        v-else
                        >



                          <h1>{{event.adres_data [ 'adres_naam' ]}}</h1>
                          <p>{{event.adres_data [ 'adres_id' ]}}  </p>

*/



import { reactive, computed } from 'vue';
import store from '@/store'


export default {

    name: 'FavorietenLayout',

    // props: {

    //      //   selectie: String,
    // },

    components: {
      //      LoadingImg
          
    },



    setup ( ) {

        const event = reactive ({

    //            imgIsLoaded: false,

                tekstkeuzenu: 0,

                volgende: 147,

                kop: '',

                bekekenbeeld: computed ((  ) => { 

                      return store.state.favorietenLocatieY;
                }),



                data: computed ((  ) => { 

                    //  return [];

                      let a = store.getters.getFavorieten();

                      // if ( a != null  )
                      // {
                      //           if ( a [ event.key_beeld ] != null )
                      //           {
                      //                 document.getElementById( event.key_beeld ).scrollIntoView();
                      //           }
                               
                      // }


                      return a;

                      // let k = store.state.menuRubriekenKeuze;

                      // let a = store.getters.getTekstData ();

                      // let d = a.filter ( c => c.rubriek_id == k );


                      // if ( d != null  )
                      // {
                      //       store.dispatch ( 'setTekstKeuze', d[0].tekst_id  );
                      // }

               //       return d;
                }),






                //  rubriekkeuze: computed (() => {

                //        //alert ( store.state.menu_status);

                //        // event.imgIsLoaded = false;

                //         return store.state.menuRubriekenKeuze;
                // }),



                //  tekstkeuze: computed (() => {

                //        //alert ( store.state.menu_status);

                //       //  let tk = store.state.tekstKeuze;


                //       // if ( event.tekstkeuzenu != tk )
                //       // {
                //       //        //event.tekstkeuzenu = tk;
                //       // }


                //         return store.state.tekstKeuze;
                // }),





                activeClass: 'thumbnail select',
                standClass: 'thumbnail',
        });




        function vertaal ( code ) {

                //return code ;
                return store.getters.getVertaling ( code );
        }



        function initPaginaData () {


               // alert ( "Naar Locatie: " + event.bekekenbeeld  );


                //console.log( "KunstwerkenThumbnails initPaginaData" );

                store.dispatch ( 'laadVertaling' );   

                store.dispatch ( 'laadThumbnails' ); 

                store.dispatch ( 'laadTekst' ); 

                //---------------------------------//


                document.title =this.vertaal ( 'naam_bedrijf' ) + " | favorieten";


                //document.getElementById( event.bekekenbeeld ).scrollIntoView();

                // let locatieItem = document.getElementById ( event.bekekenbeeld );


                // if ( locatieItem != null ) {

                //         locatieItem.scrollIntoView ( {behavior: "smooth", block: "center"});
                // }
                // else
                // {
                //     document.getElementById( "app" ).scrollIntoView({behavior: "smooth"});
                // }
                

                            //    window.scrollTo( 0,0 );
        }




            function bewaarLocatie ( bekekenbeeld ) {

                  //alert ( "bewaarLocatie: " + bekekenbeeld );

                   store.dispatch ( 'setFavorietenLocatieY', bekekenbeeld );
            }


            function unsetFotoKeuze ( id ) {

                   store.dispatch ( 'unsetFotoKeuze', id );
            }



        return { 
              event, 
              initPaginaData,
              vertaal,
              bewaarLocatie,
              unsetFotoKeuze,
        };
    },





          mounted () {

                this.initPaginaData ();

            //  console.log( "KunstwerkenThumbnails: mounted" );

               //event.imgIsLoaded = false;



                  //document.getElementById( event.key_beeld ).scrollIntoView();
          },


          updated () {

                this.initPaginaData ();

                //console.log( "KunstwerkenThumbnails: updated" );




                 //   event.key_beeld = key_beeld;

               //event.imgIsLoaded = false;
          },


}



</script>



<style scoped>




.select 
{

        font-size: 2rem;
}



/*/////////////////////////////////////*/
/*  */
/*/////////////////////////////////////*/



.pagina_blok 
{
      margin:  1rem auto;

      word-break: break-word;

}




.slowshow 
{
/*      animation-name: fadein;
      animation-duration: 3s;
      animation-delay: 2s;
      animation-fill-mode: forwards;
      animation-fill-mode: both;*/
}



@keyframes fadein 
{
        0%              { opacity: .4; }
        100%         { opacity: 1; }
}







.thumbnail_blok 
{
        margin:  0 1rem ;
}


.thumbnail 
{
        margin:  1rem 0;
        padding:  0;       


        position:  relative;

}


.thumbnail:hover
{
/*          background-color: rgb( 245, 245, 245 );*/

          border-color: rgb( 220, 220, 220 );

/*          animation: tnVergroot linear .5s;
          animation-timing-function: ease;
          animation-iteration-count: 1;
          transform-origin: 50% 70%;
          animation-fill-mode: forwards;     */
}



@keyframes tnVergroot {

        0% {
              transform: scale(1, 1);
      }

        100% {
          transform: scale(1.05, 1.05);
        }  
}


@keyframes tnxVergroot {

        0% {
              transform: scale(1, 1);
      }

        50% {
          transform: scale(1.04, 1.04);
        }

        100% {
          transform: scale(1, 1);
        }  
}



.beeld
{
      margin:  0 auto;
      padding:  0;

      line-height: 0;


         max-height: calc( 100vh - 10rem) ;
}

.thumbnail p
{
        padding:  1rem 0 1rem 0;

        font-size: 1.1rem;
        line-height: 1.3em;

        text-decoration: none;

                text-align: center;    


/*        color:  rgb(107, 210, 162);*/

      color:  rgb( 160, 160, 160 );

}




.thumbnail.select
{
        background-color: rgb( 245, 245, 245 );
}





.navigatie_buttons 
{
        line-height: 2.5rem;
}




.hartje_stop 
{
            margin:  0 auto;
            width:  2rem;
            height:  2rem;

            background-image: url( ~@/assets/hartje-stop.svg ) ; 
            background-repeat: no-repeat;
            background-position: center center;
            background-size: 2rem 2rem;     
}

.hartje_stop:hover 
{
            animation: hVergroot linear .5s;
            animation-timing-function: ease;
            animation-iteration-count: 1;
            transform-origin: 50% 50%;
            animation-fill-mode: forwards;     

            background-color: rgb( 240, 240, 240 );
}




@keyframes hVergroot {

        0% {
                transform: scale(1, 1);
      }

        50% {
                transform: scale(1.2, 1.2);
        }

        100% {
                transform: scale(1, 1);
        }  
}







@media (min-width: 30rem) 
{

      .thumbnail_blok 
      {
              margin:  0 2rem 2rem 2rem ;
      }


      .thumbnail 
      {
              margin:  0 1rem 2rem 1rem;
              padding:  0;       

              text-align: center;    
      }


}




@media (min-width: 50rem) 
{
        .pagina_blok 
        {
              margin:  0 auto 0 auto;

              display: grid;
              grid-gap: 2rem;
              grid-template-columns:  1fr 44rem 1fr;  
        }


        .koptekst 
        {
              grid-row-start: 1;
              grid-row-end: span 1;
              grid-column-start: 1;
              grid-column-end: span 3;
        }


        .navigatie_buttons 
        {
                grid-row-start: 2;
                grid-row-end: span 1;
                grid-column-start: 2;
                grid-column-end: span 1;

                line-height: 2.5rem;
        }



        .thumbnail_blok 
        {
              grid-row-start: 3;
              grid-row-end: span 1;
              grid-column-start: 2;
              grid-column-end: span 1;

              text-align: center;

              display: grid;
              grid-gap: 4rem;
              grid-gap: 0;
              grid-gap: 1rem;

              grid-template-columns: 1fr    ;  
        }




        .thumbnail 
        {
                margin:  0 0 0 0;
                padding:  2rem ;           

                border-bottom:  1px solid rgb( 230, 230, 230 );
                border-left:  1px solid rgb( 230, 230, 230 );
                border:  1px solid rgb( 240, 240, 240 );

                display: grid;
                grid-gap: 0;
                grid-row-gap: 4rem;

                grid-template-columns: 1fr;  

                background-color: rgb( 255, 255, 255 );
        }



      .thumbnail:hover
      {
                border:  1px solid rgb( 230, 230, 230 );
      }



        .beeld
        {
                margin:  0 0 0 0;
                padding:  0;

                line-height: 0;

                justify-self: center;

                align-self: center;
        }



        .thumbnail p
        {
                  padding:  .25rem 0 0 0;

                  font-size: 1rem;
                  line-height: 1.3em;
        }

        .hartje_stop 
        {
                    position: absolute;

                    right:  0;
                    bottom:  0;
        }


}







@media (min-width: 90rem) 
{
        .pagina_blok 
        {
              margin:  0 auto 0 auto;

              grid-template-columns: 1fr 52rem 1fr;  
        }


        .koptekst 
        {
              grid-row-start: 1;
              grid-row-end: span 1;
              grid-column-start: 1;
              grid-column-end: span 3;
        }


        .navigatie_buttons 
        {
                grid-row-start: 2;
                grid-row-end: span 1;
                grid-column-start: 2;
                grid-column-end: span 1;

                line-height: 2.5rem;
        }



        .thumbnail_blok 
        {

/*              grid-template-columns: 1fr  1fr 1fr ;  
*/
        }


        .thumbnail:nth-child( 2n + 0 )
        {
/*                border-top:  none;
                border-right:  none;*/
        }


        .thumbnail:nth-child( 1 ),
        .thumbnail:nth-child( 2 ),
        .thumbnail:nth-child( 3 )
        {
/*                border-top:  1px solid rgb( 230, 230, 230 );
*/
/*                border-top:  1px solid rgb( 230, 190, 230 );*/
        }



        .thumbnail:nth-child( 3n + 0 )
        {
/*                border-right:  1px solid rgb( 240, 240, 240 );*/
        }

      .thumbnail:hover
      {
/*                border:  1px solid rgb( 230, 230, 230 );
*/      }

}







.button
{
      display: inline-block;

      margin:  0 1rem;

      height:  2rem;
      width:  2rem;

/*      border:  1px solid rgb( 220, 220, 220 );
*/
      border-radius: 2rem;

      background-image: url( ~@/assets/pijl-links.svg ); 
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 2rem 2rem;     
}


.button.button_plus
{
      background-image: url( ~@/assets/pijl-rechts.svg ); 
}









</style>
