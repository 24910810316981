
<template>


       <div 
                 v-if=" event.data !== null "

                 class="pagina_blok"
                 >

                <div 
                      v-if=" event.data.length > 0 "
                      class="thumbnail_blok"
                      >


                        <router-link 
                                    v-for="(value_beeld, key_beeld) in event.data" 
                                     :key="key_beeld" 

                                     v-bind:class="[event.bekekenbeeld == 'beeld' + key_beeld  ? event.activeClass : event.standClass, '' ]"  


                                    v-bind:id= "'beeld' + key_beeld"

                                     @click="bewaarLocatie ( 'beeld' + key_beeld )"

                                     :to="{path:'/r',  query:{i: value_beeld.rubriek_id, p: value_beeld.tekst_id, info: value_beeld.tekst_url }}"
                                    >



                                  <img 
                                            v-bind:src="value_beeld.img"
                                            class="beeld"
                                             loading='lazy' 
                                            />

                                  <p><span v-html="value_beeld.rubriek_naam[ 1 ]"></span> | <span v-html="value_beeld.tekst_naam[ 1 ]"></span></p>

                          </router-link>

                </div>
  
      </div>



</template>


<script>

/*
                <p>{{event.adres_data [ 'adres_id' ]}}  </p>

*/



import { reactive, computed } from 'vue';
import store from '@/store'


export default {

    name: 'KunstwerkenThumbnails',

    // props: {

    //      //   selectie: String,
    // },

    components: {
      //      LoadingImg
          
    },



    setup ( ) {

        const event = reactive ({


                tekstkeuzenu: 0,

                volgende: 147,


                kop: '',

                bekekenbeeld: computed ((  ) => { 

                      return store.state.homeLocatieY;
                }),


                data: computed ((  ) => { 

                      return store.getters.getThumbnails();
                }),


                activeClass: 'thumbnail select',
                standClass: 'thumbnail',
        });




        function vertaal ( code ) {

                //return code ;
                return store.getters.getVertaling ( code );
        }



        function initPaginaData () {


               // alert ( "Naar Locatie: " + event.bekekenbeeld  );


                //console.log( "KunstwerkenThumbnails initPaginaData" );

                store.dispatch ( 'laadVertaling' );   

                store.dispatch ( 'laadThumbnails' ); 


                //---------------------------------//


                document.title =this.vertaal ( 'naam_bedrijf' );


                //document.getElementById( event.bekekenbeeld ).scrollIntoView();

                let item = event.bekekenbeeld;

                console.log( "event.bekekenbeeld: " + item );


                if ( item == ''  )
                {
                        console.log ( "scroll into view top " );

                        window.scrollTo (0, 0 );

                      //  item_in_beeld.scrollIntoView({behavior: "smooth"});
                }
                else
                {
                        console.log ( "scroll into view: " + item );

                        document.getElementById( item ).scrollIntoView( { behavior: "smooth", block: "center" });
                }

            
        }




            function bewaarLocatie ( bekekenbeeld ) {

                  //alert ( "bewaarLocatie: " + bekekenbeeld );

                   store.dispatch ( 'setHomeLocatieY', bekekenbeeld );
            }





        return { 
              event, 
              initPaginaData,
              vertaal,
              bewaarLocatie,
        };
    },





          mounted () {

                this.initPaginaData ();
          },


          updated () {

                this.initPaginaData ();
          },


}



</script>



<style scoped>




.select 
{

        font-size: 2rem;
}



/*/////////////////////////////////////*/
/*  */
/*/////////////////////////////////////*/



.pagina_blok 
{
      margin:  1rem auto;

      word-break: break-word;

}




.slowshow 
{
/*      animation-name: fadein;
      animation-duration: 3s;
      animation-delay: 2s;
      animation-fill-mode: forwards;
      animation-fill-mode: both;*/
}



@keyframes fadein 
{
        0%              { opacity: .4; }
        100%         { opacity: 1; }
}







.thumbnail_blok 
{
        margin:  0 1rem ;
}


.thumbnail 
{
        margin:  1rem 0;
        padding:  0;       

        text-align: center;    
}


.thumbnail:hover
{
/*          background-color: rgb( 245, 245, 245 );*/

          border-color: rgb( 220, 220, 220 );

          animation: tnVergroot linear .5s;
          animation-timing-function: ease;
          animation-iteration-count: 1;
          transform-origin: 50% 70%;
          animation-fill-mode: forwards;     
}



@keyframes tnVergroot {

        0% {
              transform: scale(1, 1);
      }

        100% {
          transform: scale(1.05, 1.05);
        }  
}


@keyframes tnxVergroot {

        0% {
              transform: scale(1, 1);
      }

        50% {
          transform: scale(1.04, 1.04);
        }

        100% {
          transform: scale(1, 1);
        }  
}



.beeld
{
    margin:  0 auto;
    padding:  0;

    line-height: 0;
}

.thumbnail p
{
        padding:  1rem 0 1rem 0;

        font-size: 1.1rem;
        line-height: 1.3em;

        text-decoration: none;
/*        color:  rgb(107, 210, 162);*/

      color:  rgb( 160, 160, 160 );

}




.thumbnail.select
{
        background-color: rgb( 245, 245, 245 );
}





.navigatie_buttons 
{
        line-height: 2.5rem;
}



@media (min-width: 30rem) 
{

        .thumbnail_blok 
      {
              margin:  0 3rem 2rem 3rem ;
      }


      .thumbnail 
      {
              margin:  0 1rem 2rem 1rem;
              padding:  0;       

              text-align: center;    
      }


}




@media (min-width: 50rem) 
{
        .pagina_blok 
        {
              margin:  0 auto 0 auto;

              display: grid;
              grid-gap: 2rem;
              grid-template-columns: 2rem 1fr 2rem;  
/*              grid-template-rows: 3rem  2rem 1fr;  
*/        }


        .koptekst 
        {
              grid-row-start: 1;
              grid-row-end: span 1;
              grid-column-start: 1;
              grid-column-end: span 3;
        }


        .navigatie_buttons 
        {
                grid-row-start: 2;
                grid-row-end: span 1;
                grid-column-start: 2;
                grid-column-end: span 1;

                line-height: 2.5rem;
        }



        .thumbnail_blok 
        {
              grid-row-start: 3;
              grid-row-end: span 1;
              grid-column-start: 2;
              grid-column-end: span 1;

              text-align: center;

              display: grid;
              grid-gap: 4rem;
              grid-gap: 0;
              grid-gap: 1rem;

              grid-template-columns: 1fr  1fr  ;  
        }


        .thumbnail 
        {
                margin:  0 0 0 0;
                padding:  6rem;           
                padding:  4rem 4rem;           

                border-bottom:  1px solid rgb( 230, 230, 230 );
                border-left:  1px solid rgb( 230, 230, 230 );
                border:  1px solid rgb( 240, 240, 240 );

/*
                border-bottom:  1px solid rgb( 240, 100, 240 );
                border-left:  1px solid rgb( 240, 240, 100 );*/

/*
                border-top:  none;*/


/*                border-right:  none;*/

/*                border-radius: 3px;
                            border-radius: 9px;*/

                display: grid;
                grid-gap: 0;
                grid-row-gap: 2rem;
                grid-template-columns: 1fr;  
                grid-template-rows: 1fr 3rem;  

                background-color: rgb( 255, 255, 255 );
        }



        .thumbnail:nth-child( 1 ),
        .thumbnail:nth-child( 2 )
        {
/*                border-top:  1px solid rgb( 230, 230, 230 );
*//*                border-top:  1px solid rgb( 230, 190, 230 );*/
        }



        .thumbnail:nth-child( 2n + 0 )
        {
/*                border-right:  1px solid rgb( 240, 240, 240 );*/
/*                border-right:  1px solid rgb( 240, 240, 100 );*/
        }


      .thumbnail:hover
      {
                border:  1px solid rgb( 230, 230, 230 );
/*                border:  1px solid rgb( 100, 240, 100 );*/
      }



        .beeld
        {
                margin:  0 0 0 0;
                padding:  0;

                line-height: 0;

                justify-self: center;

                align-self: center;
        }



        .thumbnail p
        {
                  padding:  .25rem 0 0 0;

                  font-size: 1.1rem;
                  line-height: 1.3em;

                  justify-self: stretch;

                  align-self: end;
        }

}







@media (min-width: 90rem) 
{
        .pagina_blok 
        {
              margin:  0 auto 0 auto;

   
              grid-template-columns: 2rem 1fr 2rem;  
/*              grid-template-rows: 3rem  2rem 1fr;  
*/        }


        .koptekst 
        {
              grid-row-start: 1;
              grid-row-end: span 1;
              grid-column-start: 1;
              grid-column-end: span 3;
        }


        .navigatie_buttons 
        {
                grid-row-start: 2;
                grid-row-end: span 1;
                grid-column-start: 2;
                grid-column-end: span 1;

                line-height: 2.5rem;
        }



        .thumbnail_blok 
        {

              grid-template-columns: 1fr  1fr 1fr ;  

        }


        .thumbnail:nth-child( 2n + 0 )
        {
/*                border-top:  none;
                border-right:  none;*/
        }


        .thumbnail:nth-child( 1 ),
        .thumbnail:nth-child( 2 ),
        .thumbnail:nth-child( 3 )
        {
/*                border-top:  1px solid rgb( 230, 230, 230 );
*/
/*                border-top:  1px solid rgb( 230, 190, 230 );*/
        }



        .thumbnail:nth-child( 3n + 0 )
        {
/*                border-right:  1px solid rgb( 240, 240, 240 );*/
        }

      .thumbnail:hover
      {
/*                border:  1px solid rgb( 230, 230, 230 );
*/      }

}











.button
{
      display: inline-block;

      margin:  0 1rem;

      height:  2rem;
      width:  2rem;

/*      border:  1px solid rgb( 220, 220, 220 );
*/
      border-radius: 2rem;

      background-image: url( ~@/assets/pijl-links.svg ); 
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 2rem 2rem;     
}


.button.button_plus
{
      background-image: url( ~@/assets/pijl-rechts.svg ); 
}









</style>
