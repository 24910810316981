<template>

  <ContactLayout />

</template>

<script>



  
// @ is an alias to /src

import ContactLayout from '@/components/ContactLayout.vue'





// import { 
//       reactive, 
//       computed,

// } from 'vue';

import store   from '@/store' ;



export default {

      name: 'Contact',

      components: {

              ContactLayout
     },



    setup ( ) {

        // const event = reactive ({


        // });





            function initPaginaData () {

                    store.dispatch ( 'setMenuRubriekenKeuze', 77 );
                    store.dispatch ( 'setMenuStatus', 'close' );   
            }





            return { 
                  // event, 
                   initPaginaData,

            };
       },


        beforeMount() {

                    // console.log( "AdresPagina: beforeMount" );

                   // this.initPaginaData();
        },


          mounted () {

               //  console.log( "Rubriek: mounted" );

                 this.initPaginaData();


          },


          updated () {

             //   console.log( "Rubriek: updated" );

               this.initPaginaData();

               window.scrollTo(0,0);


          },

}


</script>
