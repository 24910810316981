<template>

        <div 
                class="hoofd_menu"
                >

                

                <router-link 
                       to="/"
                       class="logo_blok"
                       >
                       <div class="logo slowShow" ></div>
                </router-link>


                 <div class="menu_button" @click="wisselMenuStatus()"></div>


                 <div 
                         class="nav_blok  menu_sluit"
                         v-bind:class="[event.menuStatus== 'open'  ? event.activeClassM : event.standClassM, '' ]"  
                        >

                        <ul  class="nav">

                                 <li 
                                        v-for="(value_menu, key_menu) in event.menurubrieken" 
                                        :key="key_menu" 
                                        v-bind:class="[event.rubriekkeuze == value_menu.rubriek_id  ? event.activeClass : event.standClass, '' ]"  
                                        >


                                         <router-link     
                                                :to="{path:'/r',  query:{i: value_menu.rubriek_id, info: value_menu.url [ event.taalkeuze ]}}"
                                                >

                                               {{value_menu.rubriek_naam [ event.taalkeuze ]}}

                                         </router-link>

                                 </li>


                                 <li 
                                        v-if="event.favorieten == true"

                                        v-bind:class="[event.rubriekkeuze == '66'  ? event.activeClass : event.standClass, '' ]" 
                                        >

                                        <router-link     
                                                 :to="{path:'/fav',  query:{i: '66'}}"
                                                >
                                            
                                            Favorieten

                                        </router-link>
                                </li>


                                 <li
                                        v-bind:class="[event.rubriekkeuze == 77  ? event.activeClass : event.standClass, '' ]"  
                                             >
                                        <router-link     
                                                 :to="{path:'/c',  query:{i: 77}}"
                                                >
                                            
                                            Contact

                                        </router-link>
                                </li>                                
                          </ul>





                </div>









      </div>

</template>


<script>
/*


                        <div id="nav" class="nav">

                                <router-link to="/kunstwerken">kunstwerken</router-link>
                                <router-link to="/exposities">exposities</router-link>
                                <router-link to="/teksten">tekst</router-link>
                                <router-link to="/contact">contact</router-link>

                        </div>





                                    <ul  
                                            v-if="event.menurubrieken.length > 0  " 
                                            >
                                              <li 
                                                       v-for="(value_menu, key_menu) in event.menurubrieken" 

                                                       :key="key_menu" 
                                                      >

                                                      {{value_menu.rubriek_naam [ 1 ] }}

                                                </li>
                                    </ul>


                                    */




import { reactive, computed } from 'vue';
import store from '@/store';



export default {

    name: 'HoofdMenu',

    components: {
      //      LoadingImg
    },

    setup () {

        const event = reactive ({

                taalkeuze: 1,
                menu_status: 0,

                //rubriekkeuze: 1,

                 menuStatus: computed (() => {

                       //alert ( store.state.menu_status);

                        return store.state.menu_status;
                }),



                 rubriekkeuze: computed (() => {

                       //alert ( store.state.menu_status);

                        return store.state.menuRubriekenKeuze;
                }),




                menurubrieken: computed (() => { 

                      return  store.getters.getMenuRubriekenData ();
                }),




                favorieten: computed ((  ) => { 

                      let a = store.getters.getFavorieten();

                      if ( a == null  )
                      {
                                return false;
                      }

                      if ( a == "undefined"  )
                      {
                                return false;
                      }

                      if ( a.length < 1  )
                      {
                                return false;
                      }

                      return true;

                }),





                // sitenaam: computed (() => { 

                //             return 'Walter Simon Beeldend Kunstenaar';
                //          // return store.getters.getVertaling ( 'site_naam' );  
                // }),


               // vertaalLogin: computed (( ) => { 

               //          return store.getters.getVertaling ( 'login' );
               //  }),



               //  taalLijst: computed (() => { 

               //        if ( store.state.taalLijst )
               //        {
               //                return store.state.taalLijst;
               //        }

               //        return [];
               //  }),


               //  taalKeuze: computed (() => { return store.state.taalKeuze  }),

            //    icon: { 0 : "icon_nl", 1: "icon_de", 2: "icon_en" },

                activeClassM: 'menu_open',
                standClassM: '' ,

                activeClass: " select",
                standClass: "" ,
        });


        function vertaal ( code ) {

                return store.getters.getVertaling ( code );
        }



       // function getIcon ( k ) {

       //          let c = '' ;

       //          if ( k == store.state.taalKeuze )
       //          {
       //                  c = " select";
       //          }

       //          if ( k == 0 )  return    " taal_button icon_nl " + c ;
       //          if ( k == 1 )  return   " taal_button icon_de " + c ;
       //          if ( k == 2 )  return   " taal_button icon_en " + c ;
            
       // }

       // const laadProjecten = async () => {
    
       //             store.dispatch ( 'laadProjecten' );  
       //  }


        function setTaalKeuze ( nr ) {

                store.dispatch ( 'setTaalKeuze', nr );
               // store.dispatch ( 'setMenuStatus', 'close' );
        }


        function wisselMenuStatus () {

              //  alert ( 'wisselMenuStatus' );

                store.dispatch ( 'wisselMenuStatus' );
        }


        return { 
                event,
                vertaal,
                setTaalKeuze, 
                wisselMenuStatus,
             };
    },


      mounted() {

            store.dispatch ( 'laadMenuRubrieken' );   
            store.dispatch ( 'setMenuStatus', 'close' );
      },


      updated() {

      },
}


</script>




<style>




.hoofd_menu 
{
        margin: 0 0 2rem 0;
        padding: 0 0  0 0;

        border-bottom: 1px solid rgb(123, 215, 148);
        border-bottom: 1px solid rgb(200, 200, 200);
}



.logo_blok 
{
       display: block;

        margin: 0;        
        padding: .2rem 0  0 .5rem;    

        min-height: 3rem;

}


.logo 
{
            display: block;
            margin:  0 1rem 0 0;

            width: calc( 100vw - 4rem);

            max-width: 16rem;

            height: 2.5rem;
            height: 3rem;

            background-image: url( ~@/assets/logo-walter-simon-m.svg ) ; 
            background-repeat: no-repeat;
            background-position: left center;
            background-size: contain;    
}




@media (min-width: 28rem) 
{ 
        .logo 
        {
    

                    display: block;
                    margin:  0 1rem 0 1rem;

                    width: calc( 100vw - 5rem);

                    max-width: 24rem;

                    height: 2.5rem;
                    height: 3rem;

                    background-image: url( ~@/assets/logo-walter-simon.svg ) ; 
        }

}

@media (min-width: 36rem) 
{ 
        .logo 
        {
                  max-width: 24rem;
        }

}







@media (min-width: 60rem) 
{ 
          .hoofd_menu 
          {
                  margin:  0 0 0 0 ;
                  padding:  1rem 0 0 0;


                  text-align: center;

                  border:  none;
          }



          a.logo_blok 
          {
                    padding: 0 0  0 0;    
                    min-height: 3rem;

                    text-align: center;

/*                 background-color: rgb( 255, 255, 255 );
*/          }


              .logo 
              {
                        margin:  0 auto 0 auto;

                        width: 100vw;
                         max-width: 42rem;

                        height: 2.25rem;

                        background-position: center center;
              }


}






/* styling menu open close button */






.nav_blok.menu_sluit 
{
        animation-name: menuNuSluiten;
        animation-duration: 1s;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
/*        transform: translateY( -200% );
*/}





.nav_blok.menu_sluit.menu_open 
{
      animation-name: menuNuOpen;
      animation-duration: .3s;
      animation-iteration-count: 1;
      animation-fill-mode: forwards;
/*      transform: translateY( 0% );
*/}







@keyframes menuNuOpen {

      0% {
                transform: translateY( -150% );
        }

      100% {
                transform: translateY( 0 );
      }  
}




@keyframes menuNuSluiten {

      0% {
                transform: translateY( 0 );
        }

      100% {
                transform: translateY( -150% )   ;  
      }  
}










.menu_button 
{
       display: block;
       position: absolute;
      z-index: 2000;

       top: 0;
       right: 0;

       width: 3rem;
       height: 3rem;
  
      background-image: url( ~@/assets/icon-hamburger.svg ) ; 
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 2rem 2rem;     
}



@media (min-width: 60rem) 
{
        .menu_button 
        {
               display: none;
        }

                .nav_blok.menu_sluit 
            {
                    animation-name: menuNuOpen;
                    animation-duration: 1s;
                    animation-iteration-count: 1;
                    animation-fill-mode: forwards;
            /*        transform: translateY( -200% );
            */}

            .nav_blok.menu_sluit.menu_open 
            {
                  animation-name: menuNuOpen;
                  animation-duration: .3s;
                  animation-iteration-count: 1;
                  animation-fill-mode: forwards;
            /*      transform: translateY( 0% );
            */}       
}








.nav 
{
    margin:  1rem 0;
    text-align: center;

    font-family: 'Work Sans', Helvetica, Arial, sans-serif;

    font-weight: 600;
    font-style: normal;

}








.nav_blok
{
      position: absolute;

      display: block;


      left: 0;
      top: 3rem;

      z-index: 200;

/*      width: calc(100% - 2rem);*/

        width:  100%;

      margin: 0 ;
      padding: 0;

      text-align: left;

      font-family: 'Work Sans', Helvetica, Arial, sans-serif;

      font-weight: 600;
      font-style: normal;


      background-color: rgb( 255, 255, 255 );
      background-color: rgb( 79, 214, 115 );
      background-color: rgb( 60, 210, 100 );

      background-color: rgb(123, 215, 148);

      background-color: rgb( 200, 200, 200 );
      background-color: rgb(158, 213, 121);


/*      box-shadow: 0 8px 8px 0 rgba( 160, 160, 160, .8 );
*/

/*        border-bottom-left-radius: 1.5rem;
        border-bottom-right-radius: 1.5rem;*/

}










@media (min-width: 60rem) 
{ 

            .nav_blok
            {
                        display: block;

                        position: relative;
                        left: 0;
                        top: 0;

/*                        display: grid;
                        grid-template-columns:  1fr 14rem;*/

                        grid-column-gap: 1rem;
                        grid-row-gap: 0;

                        z-index: 100;

                        width:  auto;

                        margin: 0 ;
                        padding: 0;

                        box-shadow: none;
                        border-radius: 0;

/*                        background-color: transparent;
*/            }


}








/* styling menu links */



ul.nav 
{
        margin:  0;
        padding:  0;

        list-style: none;

        color:  #000;
}

.nav li 
{
        display: block;
}





.nav a 
{
      display:  block;

      margin:   0;
      padding:  .5rem 1rem;




    font-weight: 500;
    text-transform: uppercase;
    text-decoration: none;



/*      color: rgb( 90, 90, 90 );*/
/*      background-color: rgb( 220, 220, 220 );*/

    color:  rgb( 255, 255, 255 );

      border-bottom:  1px solid rgb( 255, 255, 255 );
}



/*

#nav a:first-child 
{
        border-top:  1px solid rgb( 255, 255, 255 );
}

*/


.nav a.router-link-exact-active 
{
}


.nav a:hover 
{
        color:  #fff;

        background-color: rgb( 230, 230, 230);
        background-color: rgb(159, 202, 131);

/*        background-color: rgb(169, 230, 185);
*/

      animation: buttonVergroot linear .5s;
      animation-timing-function: ease;
      animation-iteration-count: 1;
      transform-origin: 50% 50%;
      animation-fill-mode: forwards;     



}





@keyframes buttonVergroot {

        0% {
              transform: scale(1, 1);
      }

        50% {
          transform: scale(1.06, 1.06);
        }

        100% {
          transform: scale(1, 1);
        }  
}








.select a
{
        background-color: rgb( 220, 220, 220 );
        background-color: rgba( 255, 255, 255, .5 );

/*        background-color: rgb(169, 230, 185);
*/}




@media (min-width: 60rem) 
{ 
            .nav li 
            {
                    display: inline-block;

                    line-height: 1.5rem;
            }


            .nav a
            {
                        display:  inline-block;

                        margin: .5rem .5rem;
                        padding:  .1rem .5rem;

                        border:  none;

                        color:  rgb( 255, 255, 255 );

                        font-weight: 400;
                        text-transform: uppercase;

/*                        border:  1px solid rgba( 255, 255, 255, .6 );
*/
                        border-radius: 2rem;
            }

            .nav a:first-child 
            {
/*                    border:  1px solid rgba( 255, 255, 255, .6 );
*/            }






}










</style>
