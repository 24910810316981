import { createStore } from 'vuex'












export default createStore({
      state: {

                   //siteImagesUrl : 'https://walter.local/app_images_01/' ,
                  siteImagesUrl : 'https://waltersimon.nl/app_images_01/' ,


                  //appDataUrl : 'https://walter.local/app-data/index.php' ,
                 appDataUrl : 'https://www.waltersimon.nl/app-data/index.php' ,
                  //appDataUrl : '/app-data/index.php' ,


                     siteVersie: { 
                            status: false, 
                            t: '', 
                            v: '',
                      },


                      taalLijst: {   
                            //   0 : "NL", 
                                1 : "NL", 
                                2 : "EN", 
                               // 3 : "DE", 
                               // 4 : "FR", 
                               // 5 : "ES" 
                         },

                      taal_ext: {   
                               0 : "", 
                               1 : "", 
                               2 : "_e", 
                               // 3 : "_d", 
                               // 4 : "_f", 
                               // 5 : "_s" 
                         },

                        taal_set: {   
                               0 : 1, 
                               1 : 1, 
                                2 : 2, 
                               // 3 : 4, 
                               // 4 : 8, 
                               // 5 : 16 
                        },



                    menu_status: 0,

                    vertaalLijst_status : false,
                    vertaalLijst : { t: '', data : [] },
                    taalKeuze: 1,

                   // menuRubrieken.status : false,

                    menuRubrieken: { 
                          status: false, 
                          t: '', 
                          data : [] 
                    },

                    menuRubriekenKeuze: -1,

                    tekst_status : false,
                    tekst: { t: '', data : [] },
                    tekstKeuze: -1,

                    tekstKeuzeData: {},

                        //TekstKeuze: -1,


                    menuRubriekTekstKeuze: [],



                     thumbnails: { 
                          status: false, 
                          t: '', 
                          data : [] 
                      },

                      fotoKeuzeLijst: [],

                      homeLocatieY: '',

                      favorietenLocatieY: 'app',



        },



        getters: {



                     //----------------------------------------------//



                     getTaalKeuze: state => ( ) => {

                            if ( state.taalKeuze < 1 )  return 1;
                            if ( state.taalKeuze < 6 )  return state.taalKeuze;

                            return 1;
                    },



                    getVertaling: state => ( code ) => {


                            if ( !state.vertaalLijst_status )
                            {
                                    return 'laden ';
                            }


                            if ( state.vertaalLijst.data === null )
                            {
                                  return 'laden ';
                            }

                            if ( state.vertaalLijst.data.length < 1  )
                            {
                                  return 'laden ';
                            }

                           // return code ;

                            let a = state.vertaalLijst.data.find ( record => record.code == code )

                           ////console.log( "getVertaling test null" ); 

                            if ( a === null ) return code;


                            ////console.log( "getVertaling test length" ); 


                            if ( a.length < 1 ) return code;

                            ////console.log( "getVertaling test naam undefined" ); 


                            if ( a.naam === "undefined" ) return code;
                            

                            let ext = state.taal_ext [ state.taalKeuze ]; 

                            return a  [ "naam" + ext ] ;
                    },



                    getVertalingStatus: state => ( ) => {

                            return state.vertaalLijst_status;
                    },



                    //----------------------------------------------//
                    //----------------------------------------------//



                    getMenuRubriekenData: state => () => {

                          if ( state.menuRubrieken.status )
                          {
                             //   alert ( 'get data' + state.menuRubrieken.data );

                                if ( state.menuRubrieken.data != "undefined" )
                                {
                                     return state.menuRubrieken.data;  
                                }
                          }

                          return [];
                  },




                    //----------------------------------------------//



                    getTekstData: state => () => {

                          if ( state.tekst_status )
                          {
                                if ( state.tekst.data != null )
                                {
                                     return state.tekst.data;  
                                }
                                                          
                          }

                          return [];
                  },



                    //----------------------------------------------//



                      getTekstKeuzeData: state => () => {


                              let tekst_id        = state.tekstKeuze;
                              let rubriek_id    = state.menuRubriekenKeuze;


                              if ( state.tekst.data == null )
                              {
                                 ////console.log( "state.tekst.data == null "); 
                                  return null ;
                              }


                              if ( state.tekst.data.length == 0 )
                              {
                                         ////console.log( "state.tekst.data.length == 0"); 

                                      return null ;
                              }


                                if ( rubriek_id < 1 )
                                {
                                      return null;
                                }


                                //-------------------------------------------------//
                                // is er een voorkeursinstelling voor deze rubriek
                                //-------------------------------------------------//


                                let r = state.menuRubriekTekstKeuze.filter ( c => c.rubriek_id == rubriek_id );

                                if ( r!= null )
                                {
                                            if ( r [ 0 ] != null )
                                            {
                                                   tekst_id =  r [ 0 ].tekst_id;
                                            }                                
                                }


                                //-------------------------------------------------//
                                // zoek de tekst of de eerste uit de lijst
                                //-------------------------------------------------//


                                let a = state.tekst.data;

                                // filter eerst op rubriek

                                let d = a.filter ( c => c.rubriek_id == rubriek_id );

                                 if ( d == null )
                                  {
                                          return null;
                                  }

                                  // filter dan op tekst  

                                  let e = d.filter ( c => c.tekst_id == tekst_id );

                                  if ( e == null )
                                  {
                                          return null;
                                  }


                                  // als er iets gevonden is dan ok 

                                  if ( e [ 0 ] != null )
                                  {
                                         return e [ 0 ];
                                  }

                                  // anders de eerste van de rubriek 
                                  
                                  if ( d [ 0 ] != null )
                                  {
                                         return d [ 0 ];
                                  }


                                  return null;
                  },




                      getTekstKeuze: state => () => {


                              let tekst_id        = state.tekstKeuze;
                              let rubriek_id    = state.menuRubriekenKeuze;


                              if ( state.tekst.data == null )
                              {
                                 ////console.log( "state.tekst.data == null "); 
                                  return 0 ;
                              }


                              if ( state.tekst.data.length == 0 )
                              {
                                         ////console.log( "state.tekst.data.length == 0"); 

                                      return 0 ;
                              }


                                if ( rubriek_id < 1 )
                                {
                                      return 0;
                                }


                                //-------------------------------------------------//
                                // is er een voorkeursinstelling voor deze rubriek
                                //-------------------------------------------------//


                                let r = state.menuRubriekTekstKeuze.filter ( c => c.rubriek_id == rubriek_id );

                                if ( r!= null )
                                {
                                            if ( r [ 0 ] != null )
                                            {
                                                   tekst_id =  r [ 0 ].tekst_id;
                                            }                                
                                }


                                //-------------------------------------------------//
                                // zoek de tekst of de eerste uit de lijst
                                //-------------------------------------------------//


                                let a = state.tekst.data;

                                // filter eerst op rubriek

                                let d = a.filter ( c => c.rubriek_id == rubriek_id );

                                 if ( d == null )
                                  {
                                          return 0;
                                  }

                                  // filter dan op tekst  

                                  let e = d.filter ( c => c.tekst_id == tekst_id );

                                  if ( e == null )
                                  {
                                          return 0;
                                  }


                                  // als er iets gevonden is dan ok 

                                  if ( e [ 0 ] != null )
                                  {
                                         return e [ 0 ].tekst_id;
                                  }

                                  // anders de eerste van de rubriek 
                                  
                                  if ( d [ 0 ] != null )
                                  {
                                         return d [ 0 ].tekst_id;
                                  }


                                  return null;
                  },




                    //----------------------------------------------//



                      getThumbnails: state => () => {

                       // //console.log( "setThumbnails - " + data [ 0].img );




                       return state.thumbnails.data
                          // if ( state.thumbnails.data !== null )
                          // {

                          //           alert ( 'getThumbnails : data ' + state.thumbnails.data [ 0].img );

                          //           return state.thumbnails.data;  
                          // }

                       //   return null ;
                  },



                    //----------------------------------------------//           


                      getFavorieten: state => () => {

                       // //console.log( "setThumbnails - " + data [ 0].img );

                                let c = [];



                                if ( state.tekst_status == 0  )
                                {
                                      return [];
                                }


                                if ( state.tekst.data == null )
                                {
                                     return [];
                                }


                                if ( state.fotoKeuzeLijst == null )
                                {
                                     return [];
                                }

                                if ( state.fotoKeuzeLijst.length == 0 )
                                {
                                    return [];
                                }


                               state.fotoKeuzeLijst.forEach ( ( item ) => { 

                                         state.tekst.data.forEach ( ( rubriek ) => { 

                                                    let beelden = rubriek [ 'beelden' ];

                                                    let beeld = beelden.filter ( c => c.id == item );

                                                    if ( beeld [ 0 ] != null )
                                                    {
                                                            //    //console.log ( "beeld gevonden: "  );
                                                            //    //console.log ( "rubriek: " + rubriek [ 'naam' ] );

                                // //                             let tekst_naam        = rubriek [ 'tekst_naam' ];
                                // //                              let rubriek_naam   = rubriek [ 'rubriek_naam' ];


                                                                let temp = 
                                                                {
                                                                            'id' :                          beeld [ 0 ].id,
                                                                            'img' :                     beeld [ 0 ].img,
                                                                            'tekst'  :                 beeld [ 0 ].tekst,

                                                                            'tekst_url' :               rubriek [ 'url' ],
                                                                            'tekst_id'     :            rubriek [ 'tekst_id' ],
                                                                            'rubriek_id'   :          rubriek [ 'rubriek_id' ],

                                                                            'tekst_naam' :        rubriek [ 'naam' ],
                                                                            'rubriek_naam' :    rubriek [ 'naam' ],
                                                                }

                                                                c.push ( temp );

                                                               // break;
                                                    }

                                         });

                                 });





                              return c;

                          // if ( state.thumbnails.data !== null )
                          // {

                          //           alert ( 'getThumbnails : data ' + state.thumbnails.data [ 0].img );

                          //           return state.thumbnails.data;  
                          // }

                       //   return null ;
                  },



                    //----------------------------------------------//           



        },




        mutations: {


                    setVoorkeuren ( state ) {

                               // //console.log ( "setVoorkeuren " );


                                //------------------------------------------//
                               // voorkeuren laden
                               //------------------------------------------//

                               if ( state.fotoKeuzeLijst.length == 0 )
                               {
                                         let lijst = localStorage.getItem ( 'fotoKeuzeLijst' ) ;

                                         if ( lijst !== "undefined" )
                                         {
                                                        state.fotoKeuzeLijst  = JSON.parse( lijst );
                                         }

                             /// localStorage.setItem ( 'fotoKeuzeLijst', state.fotoKeuzeLijst );

                               }
                    },


                  //----------------------------------------------//


                    setSiteVersie ( state, data_geladen ) {


                          let versie = 0;


                            if ( typeof data_geladen !== "undefined" )
                            {
                                    if ( typeof data_geladen.data !== "undefined" )
                                    {
                                              if ( typeof data_geladen.data.versie !== "undefined" )
                                              {
                                                    versie = data_geladen.data.versie;
                                              }
                                    }     
                            }


                            //-----------------------------------------------------//
                            // data versie geladen - hetzelfde gebleven dus klaar
                            //-----------------------------------------------------//


                             if ( localStorage.getItem ( 'versie' ) == versie  )
                             {
                                     // this.loading = false;

                                      state.siteVersie.v              =  versie;
                                   //   state.siteVersie.t               = data_geladen.t ;  // tijd
                                      state.siteVersie.status   = true ;

                                      return;
                             }
           

                            //-----------------------------------------------------//
                            // data niet hetzelfde - opslaan en reload pagina
                            //-----------------------------------------------------//


                              state.siteVersie.v = versie;

                              localStorage.setItem ( 'versie', versie );

                              state.siteVersie.status   = true ;



                              //alert ( 'updaten scherm' );

                               window.location.reload ( true );


                            //store.commit ( 'setEmail', localStorage.getItem ( 'email' ));

                            //-----------------------------------------------------//


                    },



                    //----------------------------------------------//



                    setTaalKeuze ( state, id ) {

                            state.taalKeuze = id;
                    },



                    setVertaalLijst ( state, data ) {

                            state.vertaalLijst_status = true;

                            state.vertaalLijst.data = data;
                    },



                    //----------------------------------------------//


                      setMenu ( state, menu_status ) {

                                  state.menu_status = menu_status;
                      },  



                      setMenuRubrieken ( state, data ) {

                                state.menuRubrieken.data = data;

                              state.menuRubrieken.status = true;
                    },



                    setMenuRubriekenKeuze ( state, id ) {

                              if ( id == "undefined" ) return;

                              state.menuRubriekenKeuze = id;
                    },



                    //----------------------------------------------//



                    setTekstData ( state, data ) {

                            // if ( data.length < 1 ) 
                            // {
                            //         return;
                            // }

                            if ( data == null ) return;

                            state.tekst.data = data;

                            state.tekst_status = true;
                    },



                    //----------------------------------------------//



                   setTekstKeuze ( state, id ) {

                        //  if ( id === "undefined" ) return;

                          ////console.log( "setTekstKeuze, id = "  + id ); 

                          state.tekstKeuze = id;

                          if ( state.menuRubriekenKeuze > 0 )
                          {

                                      //--------------------------------------//                         
                                      // wis eerst het item met deze rubriek
                                      //--------------------------------------//                         

                                      let a  = state.menuRubriekTekstKeuze.filter (( item ) => {

                                                  if ( item.rubriek_id != state.menuRubriekenKeuze ) return true;

                                                  return false;
                                      });


                                      //--------------------------------------//
                                      // nu de nieuwe combinatie gebruiken                 
                                      //--------------------------------------//                         


                                      a.push ( 
                                      { 
                                                rubriek_id: state.menuRubriekenKeuze , 
                                                tekst_id : id 
                                      });


                                      state.menuRubriekTekstKeuze = a;

                                      
                                      console.log( "setTekstKeuze tekst_id: " + id ); 
                                      console.log( "setTekstKeuze rubriek: " + state.menuRubriekenKeuze ); 
                                      console.log( "setTekstKeuze resultaat: " + JSON.stringify ( a ) ); 
                          }

                    },




                    //----------------------------------------------//


                    setHomeLocatieY ( state , y ) {


                              state.homeLocatieY = y;

                       //       alert ( "bewaarLocatie: " + y );

                    },


                    //----------------------------------------------//



                    setFavorietenLocatieY ( state , y ) {


                              state.favorietenLocatieY = y;

                       //       alert ( "bewaarLocatie: " + y );

                    },


                    //----------------------------------------------//



                    setThumbnails ( state, data ) {

                                ////console.log( "setThumbnails - " + data [ 0].img );


                            // if ( data.length < 1 ) 
                            // {
                            //         return;
                            // }

                            //if ( data == null ) return;

                            state.thumbnails.data = data;

                            state.thumbnails.status = true;
                    },



                    //----------------------------------------------//




                    setFotoKeuze ( state, id ) {

                        //  if ( id === "undefined" ) return;

                         // //console.log( "setFotoKeuze, id = "  + id ); 

                          //state.fotoKeuze = id;



                          //-----------------------------------------------------//
                          // als hij er is dan wissen anders toevoegen
                          //-----------------------------------------------------//

                          let a = [];

                          if ( state.fotoKeuzeLijst != null )
                          {
                                        if ( state.fotoKeuzeLijst.includes ( id ) )
                                        {
                                                 a  = state.fotoKeuzeLijst.filter ( ( item ) => {

                                                          if ( item != id ) return true;

                                                          return false;
                                                  } );
                                        }
                                        else
                                        {
                                                let nieuw = [ id ];

                                                a = nieuw.concat ( state.fotoKeuzeLijst );                           
                                        }                           
                          }
                          else
                          {
                              a =  [ id ];
                          }



                            //-----------------------------------------------------//
                            // opslaan
                            //-----------------------------------------------------//


                            state.fotoKeuzeLijst = a;

                              localStorage.setItem ( 'fotoKeuzeLijst', JSON.stringify( a ) );

                              //console.log( state.fotoKeuzeLijst ); 

                    },



                    //----------------------------------------------//



                    unsetFotoKeuze ( state, id ) {

                        //  if ( id === "undefined" ) return;

                          //console.log( "unsetFotoKeuze, id = "  + id ); 

                          //state.fotoKeuze = id;

                          if ( !state.fotoKeuzeLijst.includes ( id ) )
                          {
                                   //console.log( "niet gevonden, id = "  + id ); 

                                  return ;
                          }

                          //console.log( "unsetFotoKeuze, id = gevonden "  + id ); 

                         let a  = state.fotoKeuzeLijst.filter ( ( item ) => {

                                  if ( item != id ) return true;

                                  return false;
                          } );


                              //console.log( JSON.stringify( a ) ); 
          
                            //-----------------------------------------------------//
                            // opslaan
                            //-----------------------------------------------------//

                              state.fotoKeuzeLijst = a;


                              localStorage.setItem ( 'fotoKeuzeLijst', JSON.stringify(state.fotoKeuzeLijst) );

                              // //console.log( state.fotoKeuzeLijst ); 

                    },



                    //----------------------------------------------//

        },





        actions: {


                    //----------------------------------------------//


                     async laadSiteVersie ( context ) {

                                //alert ( 'laad laadTekst' );

                                if ( this.state.siteVersie.status   )
                                {
                                   // alert ( 'vertaling is al geladen' );

                                     ////console.log( "laadSiteVersie is al geladen" );

                                    return;  // is al geladen
                                }

                                 ////console.log( "laadSiteVersie" );

                                this.state.siteVersie.status = false;


                               //------------------------------------------//


                                let url = this.state.appDataUrl + '?t=versie';

                                //alert ( url );

                                let res     = await fetch ( url  );
                                let data  = await res.json();


                                context.commit ( 'setSiteVersie', data );


                                context.commit ( 'setVoorkeuren' );




                                ////console.log ( "SiteVersie - geladen" );


                                //if ( this.state.tekstKeuze > 0 ) context.commit ( 'setTekstKeuze',  this.state.tekstKeuze );







                               //------------------------------------------//
                      },


                  //----------------------------------------------//


                    async laadVertaling ( context ) {

                               // alert ( 'laad vertaling' );

                                if ( this.state.vertaalLijst.data.length > 0   )
                                {
                                   // alert ( 'vertaling is al geladen' );
                                    return;  // is al geladen
                                }

                                 //console.log ( "laadVertaling" );

                                 this.state.vertaalLijst_status = false;

                               //------------------------------------------//

                                let url = this.state.appDataUrl + '?t=vertaling';

                                //alert ( url );

                                let res = await fetch ( url  );

                                let data = await res.json();

                                context.commit ( 'setVertaalLijst', data.data );

                                //console.log ( "laadVertaling - geladen" );



                                 //document.title = context.getters.getVertaling ( 'naam_bedrijf' );

                               //------------------------------------------//
                      },


                      async  setTaalKeuze ( context, id ) {

                                context.commit ( 'setTaalKeuze',  id );
                        },


                       //----------------------------------------------//


                      async  setMenuStatus ( context, menu_status ) 
                      {
                            ///alert ( 'wisselMenuStatus' );
                              context.commit ( 'setMenu', menu_status )
                      },   



                     async wisselMenuStatus ( context ) 
                     {

                            if ( this.state.menu_status == 'open' )
                            {
                                context.commit ( 'setMenu', 'close' )
                            }
                            else
                            {
                                context.commit ( 'setMenu', 'open' )
                            }
                    },   


                    //----------------------------------------------//



                     async laadMenuRubrieken ( context ) {

                               // alert ( 'laad laadMenuRubrieken' );

                                if ( this.state.menuRubrieken.data.length > 0   )
                                {
                                   // alert ( 'vertaling is al geladen' );

                                     //console.log( "laadMenuRubrieken is al geladen" );

                                     return;  // is al geladen
                                }

                                 //console.log( "laadMenuRubrieken" );

                                 this.state.menuRubrieken.status = false;


                               //------------------------------------------//


                                let url = this.state.appDataUrl + '?t=menurubrieken';

                                //alert ( url );

                                let res     = await fetch ( url  );
                                let data  = await res.json();


                                context.commit ( 'setMenuRubrieken', data.data );

                                //console.log( "MenuRubrieken - geladen" );


                               // if ( this.state.menuRubriekenKeuze > 0 ) context.commit ( 'setMenuRubriekenKeuze',  this.state.menuRubriekenKeuze );


                               //------------------------------------------//
                      },




                    async  setMenuRubriekKeuze ( context, id ) {

                       //       alert ( 'setMenuRubriekenKeuze' + nr );

                              console.log( "setMenuRubriekKeuze, id = "  + id ); 

                              context.commit ( 'setMenuRubriekenKeuze',  id );
                     },


                    //----------------------------------------------//



                     async laadTekst ( context ) {

                                //alert ( 'laad laadTekst' );

                                if ( this.state.tekst.data.length > 0   )
                                {
                                   // alert ( 'vertaling is al geladen' );

                                     //console.log( "laadTekst is al geladen" );

                                    return;  // is al geladen
                                }

                                console.log( "laadTekst" );

                                 this.state.tekst_status = false;


                               //------------------------------------------//


                                let url = this.state.appDataUrl + '?t=tekst';

                                //alert ( url );

                                let res     = await fetch ( url  );
                                let data  = await res.json();

                                let a = data.data;

                                context.commit ( 'setTekstData', a );

                                console.log( "tekst geladen" );

                                this.state.tekst_status = true;


                               //------------------------------------------//

                      },






                    async  setTekstKeuze ( context, nr ) {

                              //alert ( 'setLandKeuze' + nr );

                              context.commit ( 'setTekstKeuze',  nr );
                      },






                    //--------------------------------------------------//
                    // als tekstkeuze == 0 dan de tekstkeuze bepalen
                    //--------------------------------------------------//


                    // async  initTekstKeuze ( context ) {

                    //               console.log( "initTekstKeuze"); 



                    //               if ( this.state.tekst.data == null )
                    //               {
                    //                       console.log( "this.state.tekst.data == null" ); 

                    //                       return;
                    //               }
                
                    //               if ( this.state.tekst.data.length == 0 )
                    //               {
                    //                       console.log( "this.state.tekst.data.length == 0" ); 

                    //                       return;
                    //               }





                    //              let d = [];

                    //               let a = this.state.tekst.data; // alle data


                    //               if ( this.state.tekstKeuze > 0 )
                    //               {
                    //                       console.log( " this.state.tekstKeuze > 0 " ); 


                    //                       let tk = this.state.tekstKeuze;

                    //                       d = a.filter ( c => c.tekst_id == tk );  // data bij de tekstkeuze
                    //               }
                    //               else
                    //               {
                    //                       let k = this.state.menuRubriekenKeuze;

                    //                       d = a.filter ( c => c.rubriek_id == k );
                    //               }
                                  


                    //               if ( d [ 0 ] != null )
                    //               {
                    //                       let nr = d [ 0 ].tekst_id;

                    //                       context.commit ( 'setTekstKeuze',  nr );
                    //               }
                    // },





                   //----------------------------------------------//




                    setHomeLocatieY ( context , y ) {

                            context.commit ( 'setHomeLocatieY' , y );
                              // state.homeLocatieY = y;

                            //  alert ( "bewaarLocatie: " + y );

                    },



                   //----------------------------------------------//



                    setFavorietenLocatieY ( context , y ) {

                            context.commit ( 'setFavorietenLocatieY' , y );
                              // state.homeLocatieY = y;

                            //  alert ( "bewaarLocatie: " + y );

                    },



                   //----------------------------------------------//



                     async laadThumbnails ( context ) {

                               // alert ( 'laadThumbnails' );


                                if ( this.state.thumbnails.data.length > 0   )
                                {
                                   // alert ( 'vertaling is al geladen' );

                                     //console.log ( "Thumbnails zijn al geladen" );

                                    return;  // is al geladen
                                }

                                 console.log( "laadThumbnails" );

                                 this.state.thumbnails.status = false;


                               //------------------------------------------//


                                let url = this.state.appDataUrl + '?t=thumbnails';

                             //   alert ( url );

                                let res     = await fetch ( url  );
                                let data  = await res.json();


                                context.commit ( 'setThumbnails', data.data );

                                //console.log( "laadThumbnails - geladen" );


                                //if ( this.state.tekstKeuze > 0 ) context.commit ( 'setTekstKeuze',  this.state.tekstKeuze );


                               //------------------------------------------//
                      },



                    setFotoKeuze ( context, nr ) {

                             // alert ( 'setFotoKeuze' + nr );

                              context.commit ( 'setFotoKeuze',  nr );

                      },




                    unsetFotoKeuze ( context, nr ) {

                              //alert ( 'unsetFotoKeuze' + nr );

                              context.commit ( 'unsetFotoKeuze',  nr );

                      },




         },



          modules: {
          }
})
